import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, Text } from 'react-md'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { Cell, PageTitle, ButtonLog, ButtonSuccess } from 'components/core'
import { Input, InputDocument, Switch } from 'components/form'
import Address from 'components/address'

import { handleGet, handleSave, handleValidateForms } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    document.title = 'Edição de Empresa'

    const load = async () => {
      const res = await handleGet(id)
      if (res) {
        form.reset(res)
        addressForm.reset(res.address)
      }
    }

    load()

    return () => {}
  }, [id])

  const formIsDirty = (): boolean => {
    return !form.formState.dirty && !addressForm.formState.dirty
  }

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: form, validationSchema: validationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
    )
  }

  const getOrderObj = () => {
    return {
      id: id,
      ...form.getValues(),
      address: addressForm.getValues(),
    }
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Empresa' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Principais
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <Input name='name' label='Nome *' desktopSize={3} tabletSize={4} />
                    <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
                    <Input name='ie' label='Inscrição Estadual' desktopSize={3} tabletSize={4} />
                    <Switch name='active' labelTrue='Ativo' labelFalse='Inativo' defaultChecked={false} inline desktopSize={3} tabletSize={4} />
                  </Grid>
                </Cell>
              </Grid>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Endereço
                  </Text>
                </Cell>
                <Cell>
                  <Address form={addressForm} />
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={formIsDirty()}
              onClick={async () => {
                if (await formsIsValid()) if (await handleSave(getOrderObj())) form.reset(form.getValues(), { dirty: false })
              }}
            />
            <ButtonLog entity='Company' id={id} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
