import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  initialPeriod: null,
  finalPeriod: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  initialPeriod: Yup.date().nullable().required(),
  finalPeriod: Yup.date().nullable().required(),
})
