export interface IncidentTypeList {
  value: IncidentTypeEnum | null
  label: string
}

export enum IncidentTypeEnum {
  Open = 'Open',
  Liquidated = 'Liquidated',
}

export const IncidentTypeOptions: Array<IncidentTypeList> = [
  { value: null, label: '' },
  { value: IncidentTypeEnum.Open, label: 'Sinistros em Aberto' },
  { value: IncidentTypeEnum.Liquidated, label: 'Sinistros Liquidados' },
]
