import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, FontIcon, Text, Table, TableHeader, TableRow, TableCell, TableBody, useToggle } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch, ButtonSuccess, Callout } from 'components/core'
import { handleSubmit, Input } from 'components/form'
import { CalloutType } from 'components/core/callout'
import { formatCurrency } from 'types/core/currency'
import { EditButton } from 'components/data-table'
import { TextLabel } from 'components/label'

import { defaultValues, validationSchema } from './config'
import { handleSave, handleSearch } from './actions'

import Volume from './components/volume'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [order, setOrder] = useState<any | null>(null)
  const [volumes, setVolumes] = useState<Array<any> | null>(null)
  const [volumeIndex, setVolumeIndex] = useState<number | null>(null)
  const [visibleUpdateModal, enableUpdateModal, disableUpdateModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Cubagem Manual'

    return () => {}
  }, [])

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    setOrder(null)
    setVolumes(null)
    setVolumeIndex(null)
  }

  const updateVolume = (volumeIndex: number, volume: any) => {
    if (volumes) {
      let orderVolumes: Array<any> = []
      volumes.forEach((value: any, index: number) => {
        if (index === volumeIndex) {
          value.weight = volume.weight
          value.length = volume.length
          value.height = volume.height
          value.width = volume.width
        }
        orderVolumes.push(value)
      })
      setVolumes(orderVolumes)
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Cubagem Manual' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Input name='tag' label='Chave da nota, número do pedido, código do envio ou tracking' desktopSize={6} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    let values = form.getValues()
                    if (values.tag) {
                      let order = await handleSearch(values.tag)
                      if (order?.volumes) {
                        setOrder(order)
                        let orderVolumes: Array<any> = []
                        order.volumes.forEach((x: any) => {
                          orderVolumes.push({
                            volumeId: x.volumeId,
                            weight: null,
                            height: null,
                            length: null,
                            width: null,
                          })
                        })
                        setVolumes(orderVolumes)
                      }
                    }
                  })
                }
              />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => clear()} />
            </CardActions>
          </Card>
        </Cell>
      </Grid>
      {order && (
        <Grid>
          <Cell>
            <Card fullWidth>
              <CardContent>
                <Grid>
                  <TextLabel name='clientFantasyName' label='Cliente' value={order.clientFantasyName} desktopSize={4} tabletSize={4} />
                  <TextLabel name='orderNumber' label='Núm. Pedido' value={order.orderNumber} desktopSize={2} tabletSize={4} />
                  <TextLabel name='fiscalNoteNumber' label='Núm. Nota Fiscal' value={order.fiscalNoteNumber} desktopSize={2} tabletSize={4} />
                  <TextLabel name='recipientName' label='Destinatário' value={order.recipientName} desktopSize={4} tabletSize={4} />
                </Grid>
                <Grid>
                  <Cell>
                    {order?.volumes?.length === 0 && <Callout text={'Nenhum volume localizado'} type={CalloutType.Info} />}
                    {order?.volumes?.length > 0 && (
                      <>
                        <Cell>
                          <Text type='headline-6' margin='none'>
                            Volumes
                          </Text>
                        </Cell>
                        <Card fullWidth>
                          <CardContent>
                            <Table fullWidth>
                              <TableHeader>
                                <TableRow>
                                  <TableCell>Peso</TableCell>
                                  <TableCell>Comprimento</TableCell>
                                  <TableCell>Altura</TableCell>
                                  <TableCell>Largura</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHeader>
                              <TableBody>
                                {volumes &&
                                  order?.volumes.map((_: any, index: number) => (
                                    <TableRow key={index}>
                                      <TableCell>{volumes[index]?.weight > 0 ? `${formatCurrency(volumes[index].weight)}kg` : '-'}</TableCell>
                                      <TableCell>{volumes[index]?.length > 0 ? `${volumes[index].length}cm` : '-'}</TableCell>
                                      <TableCell>{volumes[index]?.height > 0 ? `${volumes[index].height}cm` : '-'}</TableCell>
                                      <TableCell>{volumes[index]?.width > 0 ? `${volumes[index].width}cm` : '-'}</TableCell>
                                      <TableCell align='right'>
                                        <EditButton
                                          id={`edit-${index}`}
                                          action={() => {
                                            setVolumeIndex(index)
                                            enableUpdateModal()
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </>
                    )}
                  </Cell>
                </Grid>
              </CardContent>
              <CardActions>
                <ButtonSuccess
                  text='Salvar'
                  icon={<FontIcon>done</FontIcon>}
                  disabled={!form.formState.dirty}
                  onClick={async () =>
                    await handleSubmit(form, validationSchema, async () => {
                      if (
                        await handleSave({
                          trackingCode: order.trackingCode,
                          volumes: volumes,
                        })
                      )
                        clear()
                    })
                  }
                />
              </CardActions>
            </Card>
          </Cell>
        </Grid>
      )}

      <Volume index={volumeIndex} visibleModal={visibleUpdateModal} updateVolume={updateVolume} disableModal={disableUpdateModal} />
    </>
  )
}

export default Component
