import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { DateLabel, DecimalLabel, NumberLabel, TextLabel } from 'components/label'
import { FiscalDocumentEnum } from 'types/order/fiscal-document'
import { Cell } from 'components/core'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Fiscais
        </Text>
      </Cell>
      <Cell>
        <Grid>
          {order.fiscalDocument === FiscalDocumentEnum.Declaration && (
            <>
              <TextLabel name='orderNumber' label='Núm. Pedido' value={order.orderNumber} desktopSize={2} tabletSize={4} />
              <DecimalLabel name='totalValue' label='Valor Declarado' value={order.totalValue} desktopSize={2} tabletSize={4} />
            </>
          )}

          {order.fiscalDocument === FiscalDocumentEnum.FiscalNote && (
            <>
              <TextLabel name='orderNumber' label='Núm. Pedido' value={order.orderNumber} desktopSize={3} tabletSize={3} />
              <TextLabel name='fiscalNoteNumber' label='Núm. Nota Fiscal' value={order.fiscalNoteNumber} desktopSize={2} tabletSize={3} />
              <NumberLabel name='fiscalNoteSerie' label='Série Nota Fiscal' value={order.fiscalNoteSerie} desktopSize={2} tabletSize={2} />
              <DateLabel name='fiscalNoteDate' label='Data Nota Fiscal' value={order.fiscalNoteDate} desktopSize={2} tabletSize={4} />
              <DecimalLabel name='totalValue' label='Valor Declarado' value={order.totalValue} desktopSize={3} tabletSize={4} />
              <TextLabel name='fiscalNoteAccessKey' label='Chave Nota Fiscal' value={order.fiscalNoteAccessKey} desktopSize={12} tabletSize={8} />
            </>
          )}
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
