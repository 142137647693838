import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  document: null,
  ie: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(60, 'O nome deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().required().max(19, 'O cpf/cnpj deve ter no máximo 19 caracteres'),
  ie: Yup.string().nullable().required().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
})
