export const getTicketStatusDescription = (status: string): string => {
  return TicketStatusOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface TicketStatusList {
  value: TicketStatusEnum | null
  label: string
}

export enum TicketStatusEnum {
  Open = 'Open',
  Closed = 'Closed',
  WaitingFmTransportes = 'WaitingFmTransportes',
  WaitingStore = 'WaitingStore',
}

export const TicketStatusOptions: Array<TicketStatusList> = [
  { value: null, label: '' },
  { value: TicketStatusEnum.Open, label: 'Aberto' },
  { value: TicketStatusEnum.WaitingFmTransportes, label: 'Aguardando Fm Transportes' },
  { value: TicketStatusEnum.WaitingStore, label: 'Aguardando Loja' },
  { value: TicketStatusEnum.Closed, label: 'Fechado' },
]
