import React from 'react'
import { format } from 'date-fns'
import Barcode from 'react-barcode'

import { formatDocument } from 'components/form/types'
import { formatCurrency } from 'types/core/currency'

interface ComponentProps {
  orders: Array<any>
}

const Component = React.forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
  let { orders } = props

  return (
    <>
      {orders && (
        <div ref={ref}>
          <style type='text/css' media='print'>
            {'@page { margin: 10px 10px 10px 10px !important; }'}
          </style>
          {orders.map((order: any, index: number) => (
            <div key={index} style={{ pageBreakAfter: 'always', padding: '2rem' }}>
              <div style={{ float: 'left' }}>
                <Barcode value={order.trackingCode} format='CODE128B' height={60} width={1} textAlign='center' />
              </div>

              <div style={{ float: 'right' }}>
                <Barcode value={order.trackingCode} format='CODE128B' height={60} width={1} textAlign='center' />
              </div>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <p style={{ textAlign: 'center' }}>DECLARAÇÃO DE NÃO CONTRIBUINTE DO ICMS</p>

              <br />
              <br />

              {order.clientDocument > 11 && (
                <>
                  DECLARAMOS PARA OS DEVIDOS FINS QUE NOSSA EMPRESA
                  <b> {order.clientFantasyName}</b> INSCRITA SOB O CNPJ Nº <b>{formatDocument(order.clientDocument)}</b>, ESTABELECIDA NO ENDEREÇO
                  <b>
                    {' '}
                    {order.clientAddress.street}
                    {order.clientAddress.number ? ', ' + order.clientAddress.number : ''}, {order.clientAddress.neighborhood},{' '}
                    {order.clientAddress.city}/{order.clientAddress.state}{' '}
                  </b>
                  NÃO É CONTRIBUINTE DO ICMS, PORTANTO NÃO POSSUO NOTA FISCAL PARA EFEITO DE CIRCULAÇÃO DOS SEGUINTES MATERIAIS ABAIXO ESPECIFICADOS:
                </>
              )}

              {order.clientDocument < 11 && (
                <>
                  EU,
                  <b>{order.clientFantasyName}</b> INSCRITO SOB O CPF Nº <b>{formatDocument(order.clientDocument)}</b>, ESTABELECIDO NO ENDEREÇO
                  <b>
                    {order.clientAddress.street}
                    {order.clientAddress.number ? ', ' + order.clientAddress.number : ''}, {order.clientAddress.neighborhood},{' '}
                    {order.clientAddress.city}/{order.clientAddress.state}
                  </b>
                  DECLARO PARA OS DEVIDOS FINS QUE NÃO SOU CONTRIBUINTE DO ICMS, PORTANTO NÃO POSSUO NOTA FISCAL PARA EFEITO DE CIRCULAÇÃO DOS
                  SEGUINTES MATERIAIS ABAIXO ESPECIFICADOS:
                </>
              )}

              <table style={{ width: '100%', border: '1px solid', borderCollapse: 'collapse', marginTop: '2rem', marginBottom: '2rem' }}>
                <thead>
                  <tr style={{ border: '1px solid' }}>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>Descrição do Produto</td>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>Volumes</td>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>Valor</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: '1px solid' }}>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>{order.nature}</td>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>{order.volumes}</td>
                    <td style={{ border: '1px solid', textAlign: 'center' }}>{formatCurrency(order.totalValue)}</td>
                  </tr>
                </tbody>
              </table>

              <p>
                OS MESMOS SERÃO ENVIADOS AO SEGUINTE DESTINATÁRIO
                <br />
                <br />
                {order.recipientName}
                <br />
                <span>{formatDocument(order.recipientDocument)}</span>
                <br />
                {order.address.street}
                {order.address.number ? ', ' + order.address.number : ''}, {order.address.neighborhood}, {order.address.city}/{order.address.state}
                <br />
                Pedido: {order.orderNumber}
                <br />
                <br />
                ATENCIOSAMENTE
                <br />
                <br />
                {order.clientFantasyName} <br />
                {order.clientAddress.city} - {format(new Date(), 'dd/MM/yyyy')}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  )
})

export default Component
