import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, Text } from 'react-md'

import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import ButtonSuccess from 'components/core/button-success'
import { Input, InputDocument } from 'components/form'
import { Cell, PageTitle } from 'components/core'
import Address from 'components/address'

import { defaultValues, validationSchema } from './config'
import { handleSave, handleValidateForms } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })

  useEffect(() => {
    document.title = 'Nova Empresa'

    return () => {}
  }, [])

  const formIsDirty = (): boolean => {
    return !form.formState.dirty && !addressForm.formState.dirty
  }

  const formsIsValid = async (): Promise<boolean> => {
    return await handleValidateForms(
      { form: form, validationSchema: validationSchema },
      { form: addressForm, validationSchema: addressValidationSchema },
    )
  }

  const getOrderObj = () => {
    return {
      ...form.getValues(),
      address: addressForm.getValues(),
    }
  }

  const resetForms = () => {
    form.reset(defaultValues, { dirty: false })
    addressForm.reset(addressDefaultValues, { dirty: false })
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Nova Empresa' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Principais
                  </Text>
                </Cell>
                <Cell>
                  <Grid>
                    <Input name='name' label='Nome *' desktopSize={6} tabletSize={8} />
                    <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
                    <Input name='ie' label='Inscrição Estadual' desktopSize={3} tabletSize={4} />
                  </Grid>
                </Cell>
              </Grid>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Endereço
                  </Text>
                </Cell>
                <Cell>
                  <Address form={addressForm} />
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={formIsDirty()}
              onClick={async () => {
                if (await formsIsValid()) if (await handleSave(getOrderObj())) resetForms()
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
