import api from 'services/ticket/ticket'

export const handleGet = async (id: string) => {
  let res = await api.getByIdFm(id)

  return res?.data?.data
}

export const handleAssign = async (id: string) => {
  let res = await api.assignResponsible({ id })

  return res?.data?.success
}
