import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, TabsManager, TabPanels, TabPanel, Tabs, FontIcon, useToggle, ListItem } from 'react-md'
import { useReactToPrint } from 'react-to-print'

import { Cell, PageTitle, ButtonLog, ButtonEdit, Actions } from 'components/core'
import { DateTimeLabel, NumberLabel, TextLabel } from 'components/label'

import { handleGet, handlePrint } from './actions'

import Orders from './components/orders'
import Print from './components/print'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [packingList, setPackingList] = useState<any>()
  const [printPackingList, setPrintPackingList] = useState<any>()
  const [loadRequests, setLoadRequests] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const componentRef = useRef(null)

  useEffect(() => {
    document.title = 'Edição de Romaneio de Entrega'

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadRequests(true)

    return () => {}
  }, [activeTab])

  const load = async () => {
    const res = await handleGet(id)
    if (res) setPackingList(res)
  }

  const print = async () => {
    const res = await handlePrint(id)
    if (res) setPrintPackingList(res)
  }

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Romaneio' })

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Romaneio de Entrega' />
          <TabsManager
            tabs={['Romaneio', 'Pedidos']}
            activeIndex={activeTab}
            onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
            tabsId='packing-list-tabs'>
            <Tabs />
            <TabPanels persistent={true}>
              <TabPanel>
                <Card fullWidth>
                  <CardContent>
                    <Grid>
                      <NumberLabel name='number' label='Número' value={packingList?.number} desktopSize={3} tabletSize={4} />
                      <TextLabel name='code' label='Código' value={packingList?.code} desktopSize={3} tabletSize={4} />
                      <TextLabel name='carrierName' label='Portador' value={packingList?.carrierName} desktopSize={6} tabletSize={8} />
                      <DateTimeLabel name='createdAt' label='Data de Criação' value={packingList?.createdAt} desktopSize={3} tabletSize={4} />
                      <DateTimeLabel name='releasedAt' label='Data de liberação' value={packingList?.releasedAt} desktopSize={3} tabletSize={4} />
                      <DateTimeLabel name='finishedAt' label='Data de Finalização' value={packingList?.finishedAt} desktopSize={3} tabletSize={4} />
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                    <ButtonLog entity='DevolutionPackingList' id={id} />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel>{loadRequests && <Orders packingListId={id} />}</TabPanel>
            </TabPanels>
          </TabsManager>
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>print</FontIcon>} disabled={packingList?.finishedAt} onClick={() => print()}>
          Imprimir
        </ListItem>
      </Actions>

      {printPackingList && (
        <div style={{ display: 'none' }}>
          <Print packingList={printPackingList} handlePrint={handlePrintHTML} ref={componentRef} />
        </div>
      )}
    </>
  )
}

export default Component
