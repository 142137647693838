import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid, Text } from 'react-md'

import { getCteTypeDescription } from 'types/invoicing/cte-type'
import Callout, { CalloutType } from 'components/core/callout'
import { Cell, Modal } from 'components/core'
import { TextLabel } from 'components/label'

import { handleFix } from './actions'

interface ComponentProps {
  cte: any
  visibleModal: boolean
  disableModal: () => void
  onSuccess: () => void
}

const Component: React.FC<ComponentProps> = ({ cte, visibleModal, disableModal, onSuccess }) => {
  const [validations, setValidations] = useState<Array<any>>()

  useEffect(() => {
    if (cte) setValidations(cte.notifications)
    else setValidations(undefined)

    return () => {}
  }, [cte, visibleModal])

  return (
    <Modal
      title={'Correção de Cte Inválido'}
      visible={visibleModal}
      confirmTitle={'Emitir'}
      confirmAction={async () => {
        if (await handleFix({ trackingCode: cte.trackingCode, type: cte.type })) {
          disableModal()
          onSuccess()
        }
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        disableModal()
      }}>
      <Grid>
        <Cell>
          {validations && (
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Validações
                </Text>
              </Cell>
              <Cell>
                <Card style={{ backgroundColor: CalloutType.Error }} fullWidth>
                  <CardContent style={{ paddingBottom: '1rem' }}>
                    {validations.map((data: any, index: number) => (
                      <Text key={index} type='body-2' style={{ color: 'black' }} margin={'none'}>
                        {data}
                      </Text>
                    ))}
                  </CardContent>
                </Card>
              </Cell>
            </Grid>
          )}
          <Grid>
            <Cell>
              <Text type='headline-6' margin='none'>
                Informações Principais
              </Text>
            </Cell>
            <Cell>
              <Grid>
                <TextLabel name='trackingCode' label='Tracking' value={cte?.trackingCode ?? ''} desktopSize={6} tabletSize={4} />
                <TextLabel name='type' label='Tipo' value={cte ? getCteTypeDescription(cte.type) : ''} desktopSize={4} tabletSize={4} />
              </Grid>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
