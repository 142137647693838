import React, { Fragment, useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardContent, Divider, ExpansionList, ExpansionPanel, FontIcon, usePanels } from 'react-md'
import { Grid, useToggle } from '@react-md/utils'

import { Cell, PageTitle, ButtonSuccess, Callout } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { NumberLabel, TextLabel } from 'components/label'
import { formatCepMask } from 'components/address/types'
import { CalloutType } from 'components/core/callout'

import { handleListAddresses } from './actions'
import { defaultValuesSearch } from './config'

import ExistingAddress from './components/existing-address'
import AnotherAddress from './components/another-address'

const Component: React.FC = () => {
  const formSearch = useForm({ defaultValues: defaultValuesSearch })
  const client = formSearch.watch('client')
  const [visibleExistingAddress, enableExistingAddress, disableExistingAddress] = useToggle(false)
  const [pickupAddressId, setPickupAddressId] = useState<any>()
  const [addresses, setAddresses] = useState<Array<any>>()
  const [panels, onKeyDown] = usePanels({
    count: 2,
    idPrefix: 'configuring-panels',
    multiple: false,
    defaultExpandedIndex: 0,
  })

  useEffect(() => {
    document.title = 'Nova Solicitação de Coleta'

    return () => {}
  }, [])

  useEffect(() => {
    const load = async () => {
      if (client?.id) {
        const res = await handleListAddresses(client?.id ?? '')
        if (res) setAddresses(res)
        else setAddresses(undefined)
      } else setAddresses(undefined)
    }

    load()

    return () => {}
  }, [client])

  const reset = () => {
    formSearch.reset(defaultValuesSearch, { dirty: false })
    setAddresses(undefined)
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Nova Solicitação de Coleta' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...formSearch}>
                <Grid>
                  <ClientAutoComplete name='client' label='Remetente' desktopSize={4} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
          </Card>

          {client?.id && (
            <Card fullWidth>
              <CardContent>
                <ExpansionList onKeyDown={onKeyDown}>
                  <ExpansionPanel {...panels[0]} header='Endereços Cadastrados'>
                    {addresses?.length === 0 && <Callout text={'Nenhum endereço de coleta localizado'} type={CalloutType.Info} />}
                    {addresses &&
                      addresses.length > 0 &&
                      addresses.map((addressData: any, index) => (
                        <Fragment key={index}>
                          <Grid>
                            <TextLabel name='zipCode' label='Cep' value={formatCepMask(addressData.address.zipCode)} desktopSize={2} tabletSize={4} />
                            <TextLabel name='state' label='Estado' value={addressData.address.state} desktopSize={2} tabletSize={4} />
                            <NumberLabel
                              name='cityIbgeCode'
                              label='Cód. Ibge da Cidade'
                              value={addressData.address.cityIbgeCode}
                              desktopSize={2}
                              tabletSize={4}
                            />
                            <TextLabel name='city' label='Cidade *' value={addressData.address.city} desktopSize={3} tabletSize={4} />
                            <TextLabel name='neighborhood' label='Bairro *' value={addressData.address.neighborhood} desktopSize={3} tabletSize={4} />
                            <TextLabel name='street' label='Logradouro *' value={addressData.address.street} desktopSize={6} tabletSize={4} />
                            <TextLabel name='number' label='Número *' value={addressData.address.number} desktopSize={2} tabletSize={2} />
                            <TextLabel name='complement' label='Complemento' value={addressData.address.complement} desktopSize={4} tabletSize={2} />
                            <Cell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                              <ButtonSuccess
                                text='Selecionar'
                                icon={<FontIcon>done</FontIcon>}
                                onClick={async () => {
                                  setPickupAddressId(addressData.id)
                                  enableExistingAddress()
                                }}
                              />
                            </Cell>
                          </Grid>
                          {addresses.length > 1 && <Divider />}
                        </Fragment>
                      ))}
                  </ExpansionPanel>
                  <ExpansionPanel {...panels[1]} header='Outro Endereço'>
                    <AnotherAddress clientId={client.id} reset={reset} />
                  </ExpansionPanel>
                </ExpansionList>
              </CardContent>
            </Card>
          )}
        </Cell>
      </Grid>

      {visibleExistingAddress && (
        <ExistingAddress pickupAddressId={pickupAddressId} clientId={client.id} disableModal={disableExistingAddress} reset={reset} />
      )}
    </>
  )
}

export default Component
