import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/invoicing/report'
const Api = {
  invoicingPreviewClientReport: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/invoicing-preview/client`, request)),
  invoicingPreviewFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/invoicing-preview/fm`, request)),
}

export default Api
