import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { formatDocument } from 'components/form/types'
import { TextLabel } from 'components/label'
import { Cell } from 'components/core'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Destinatário
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='name' label='Nome' value={order.recipient.name} desktopSize={12} tabletSize={8} />
          <TextLabel name='document' label='Cpf/Cnpj' value={formatDocument(order.recipient.document)} desktopSize={3} tabletSize={4} />
          <TextLabel name='ie' label='Inscrição Estadual' value={order.recipient.ie} desktopSize={3} tabletSize={4} />
          <TextLabel name='email' label='Email' value={order.recipient.email} desktopSize={3} tabletSize={4} />
          <TextLabel name='phone' label='Telefone' value={order.recipient.phone} desktopSize={3} tabletSize={4} />
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
