import Yup from 'utils/yup'

export const defaultValues = {
  comment: null,
  close: false,
  returnDate: null,
}

export const validationSchema = Yup.object().shape({
  comment: Yup.string().nullable().required().max(300, 'O comentário deve ter no máximo 300 caracteres'),
  close: Yup.bool().nullable().required(),
  returnDate: Yup.date().nullable(),
})
