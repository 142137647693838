import { trackPromise } from 'react-promise-tracker'

import { ApiResponse } from 'types/core/api'
import api from 'utils/api'

const url = '/v1/cte/validate-creation'
const Api = {
  searchCreateCte: (): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/search`)),
  discardCreateCte: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/discard`, request)),
  fixCreateCte: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/fix`, request)),
}

export default Api
