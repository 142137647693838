import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import DevolutionPackingListEdit from 'pages/devolution/devolution-packing-list/edit'
import DevolutionPackingListSearch from 'pages/devolution/devolution-packing-list/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.devolution.devolutionPackingList && (
        <>
          <Route path='/devolution/devolution-packing-list/:id/edit' exact component={DevolutionPackingListEdit} />
          <Route path='/devolution/devolution-packing-list/search' exact component={DevolutionPackingListSearch} />
        </>
      )}
    </>
  )
}

export default Routes
