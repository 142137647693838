import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/ticket'
const Api = {
  getByIdClient: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/client`)),
  getByIdFm: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/fm`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addComment: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/comment`, request)),
  searchClient: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/client`, request)),
  searchFm: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/fm`, request)),
  searchByOrder: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search/order`, request)),
  assignResponsible: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/assign-responsible`, request)),
}

export default Api
