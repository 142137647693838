export interface PrintContentDeclarationClientOrderList {
  value: PrintContentDeclarationClientOrderEnum | null
  label: string
}

export enum PrintContentDeclarationClientOrderEnum {
  CreationDate = 'CreationDate',
  RecipientName = 'RecipientName',
  OrderNumber = 'OrderNumber',
}

export const PrintContentDeclarationClientOrderOptions: Array<PrintContentDeclarationClientOrderList> = [
  { value: PrintContentDeclarationClientOrderEnum.CreationDate, label: 'Data de Criação' },
  { value: PrintContentDeclarationClientOrderEnum.RecipientName, label: 'Nome do Destinatário' },
  { value: PrintContentDeclarationClientOrderEnum.OrderNumber, label: 'Núm. Pedido' },
]
