import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent, Text } from 'react-md'

import { DateTimeLabel, NumberLabel, TextLabel } from 'components/label'
import { formatCepMask } from 'components/address/types'
import { Cell, PageTitle } from 'components/core'

import { handleGet } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [log, setLog] = useState<any>()

  useEffect(() => {
    document.title = 'Detalhes do Log'

    const load = async () => {
      const res = await handleGet(id)
      if (res) setLog(res)
    }

    load()

    return () => {}
  }, [id])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Detalhes do Log' />
        {log && (
          <Card fullWidth>
            <CardContent>
              <Grid>
                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Principais
                  </Text>
                </Cell>

                <TextLabel name='clientFantasyName' label='Cliente' value={log.clientFantasyName} desktopSize={6} tabletSize={8} />
                <DateTimeLabel name='createdAt' label='Data de Criação' value={log.createdAt} desktopSize={3} tabletSize={4} />
                <DateTimeLabel name='deletedAt' label='Data Nota Fiscal' value={log.DeletedAt} desktopSize={3} tabletSize={4} />
                <TextLabel name='userName' label='Usuário' value={log.userName} desktopSize={6} tabletSize={8} />
                <TextLabel name='trackingCode' label='Tracking' value={log.trackingCode} desktopSize={3} tabletSize={4} />
                <TextLabel name='shippingCode' label='Cód. Envio' value={log.shippingCode} desktopSize={3} tabletSize={4} />

                <Cell>
                  <Text type='headline-6' margin='none'>
                    Destinatário
                  </Text>
                </Cell>

                <TextLabel name='name' label='Nome' value={log.name} desktopSize={9} tabletSize={4} />
                <TextLabel name='document' label='Cpf/Cnpj' value={log.document} desktopSize={3} tabletSize={4} />

                <Cell>
                  <Text type='headline-6' margin='none'>
                    Endereço
                  </Text>
                </Cell>

                <TextLabel name='zipCode' label='Cep' value={formatCepMask(log.address.zipCode)} desktopSize={2} tabletSize={4} />
                <TextLabel name='state' label='Estado *' value={log.address.state} desktopSize={2} tabletSize={4} />
                <NumberLabel name='cityIbgeCode' label='Cód. Ibge da Cidade' value={log.cityIbgeCode} maxLength={7} desktopSize={2} tabletSize={4} />
                <TextLabel name='city' label='Cidade' value={log.city} desktopSize={3} tabletSize={4} />
                <TextLabel name='neighborhood' label='Bairro' value={log.neighborhood} desktopSize={3} tabletSize={4} />
                <TextLabel name='street' label='Logradouro' value={log.street} desktopSize={6} tabletSize={4} />
                <TextLabel name='number' label='Número' value={log.number} desktopSize={2} tabletSize={2} />
                <TextLabel name='complement' label='Complemento' value={log.complement} desktopSize={4} tabletSize={2} />

                <Cell>
                  <Text type='headline-6' margin='none'>
                    Informações Fiscais
                  </Text>
                </Cell>

                <TextLabel name='orderNumber' label='Núm. Pedido' value={log.orderNumber} desktopSize={3} tabletSize={4} />
                <TextLabel name='fiscalNoteNumber' label='Núm. Nota Fiscal' value={log.fiscalNoteNumber} desktopSize={3} tabletSize={4} />
                <TextLabel name='fiscalNoteAccessKey' label='Chave Nota Fiscal' value={log.fiscalNoteAccessKey} desktopSize={6} tabletSize={8} />
              </Grid>
            </CardContent>
          </Card>
        )}
      </Cell>
    </Grid>
  )
}

export default Component
