import React from 'react'
import Barcode from 'react-barcode'

import { formatCepMask } from 'components/address/types'

import LogoWhite from 'assets/logo-menu.png'

interface ComponentProps {
  orders: Array<any>
}

const Component = React.forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
  let { orders } = props

  return (
    <>
      {orders && (
        <div ref={ref}>
          {orders.map((order: any, index: number) => (
            <div key={index} style={{ pageBreakAfter: 'always' }}>
              <div style={{ padding: '0.3rem' }}>
                <div style={{ height: '14.5cm', width: '10cm', border: '0.1rem solid' }}>
                  <div style={{ borderBottom: '1px solid', padding: '0.2rem' }}>
                    <img src={LogoWhite} alt='logo' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: '4rem' }} />
                  </div>

                  <div style={{ margin: '0.3rem' }}>
                    <span style={{ backgroundColor: 'black', color: 'white' }}>DESTINATÁRIO</span>
                  </div>

                  <div style={{ borderBottom: '1px solid', padding: '0.3rem' }}>
                    <div style={{ fontSize: 'small' }}>{order.recipientName}</div>

                    <div style={{ fontSize: 'small' }}>
                      {order.address.street}
                      {order.address.number ? ', ' + order.address.number : ''}, {order.address.neighborhood}
                    </div>

                    <div style={{ fontSize: 'small' }}>
                      {formatCepMask(order.address.zipCode)} {order.address.city}/{order.address.state}
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Barcode
                              value={order.address.zipCode.toString()}
                              format='CODE128B'
                              height={60}
                              width={1}
                              margin={0}
                              displayValue={false}
                              renderer='img'
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <table width='100%'>
                    <tbody>
                      <tr>
                        <td align='center' style={{ padding: '0.1rem' }}>
                          <Barcode
                            value={order.trackingCode}
                            format='CODE128B'
                            height={90}
                            textAlign='center'
                            width={1}
                            margin={1}
                            fontSize={15}
                            renderer='img'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style={{ borderTop: '1px solid', padding: '0.3rem' }}>
                    {order.orderNumber && <div style={{ fontSize: 'small' }}>Pedido: {order.orderNumber}</div>}
                    {order.fiscalNoteNumber && <div style={{ fontSize: 'small' }}>Nota Fiscal: {order.fiscalNoteNumber}</div>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
})

export default Component
