import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { getTicketStatusDescription } from 'types/ticket/ticket-status'
import { getTicketTypeDescription } from 'types/ticket/ticket-type'
import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'

import { handleEdit, handleSearch } from './actions'

interface ComponentProps {
  orderId: string
}

const Component: React.FC<ComponentProps> = ({ orderId }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    if (orderId) setFilter({ orderId })

    return () => {}
  }, [orderId])

  return (
    <DataTable
      filter={filter}
      header={[{ label: 'Número' }, { label: 'Data de Abertura' }, { label: 'Tipo' }, { label: 'Status' }]}
      result={result}
      setResult={setResult}
      search={handleSearch}
      total={result ? result.total : 0}>
      {result &&
        result.results &&
        result.results.map((data: any, index) => (
          <TableRow key={index}>
            <TableCell>{data.id}</TableCell>
            <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</TableCell>
            <TableCell>{getTicketTypeDescription(data.type)}</TableCell>
            <TableCell>{getTicketStatusDescription(data.status)}</TableCell>
            <TableCell align='right'>
              <EditButton id={`edit-${index}`} action={() => handleEdit(data.externalId)} />
            </TableCell>
          </TableRow>
        ))}
    </DataTable>
  )
}

export default Component
