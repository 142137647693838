import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getDevolutionMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions.devolution.devolutionPackingList) {
    menu['/devolution'] = getMenu('/devolution', null, 'Devolução', 'local_shipping', null)

    if (permissions.devolution.devolutionPackingList) {
      menu['/devolution/devolution-packing-list'] = getMenu('/devolution/devolution-packing-list', '/devolution', 'Romaneio', 'flag_circle', null)
      menu['/devolution/devolution-packing-list/search'] = getMenu(
        '/devolution/devolution-packing-list/search',
        '/devolution/devolution-packing-list',
        'Pesquisa',
        'search',
        '/devolution/devolution-packing-list/search',
      )
    }
  }
}
