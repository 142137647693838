import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getTicketFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.ticket.fm) {
    menu['/ticket/fm'] = getMenu('/ticket/fm', null, 'Ticket', 'forum', null)

    menu['/ticket/search/fm'] = getMenu('/ticket/search/fm', '/ticket/fm', 'Pesquisa', 'search', '/ticket/search/fm')
  }
}
