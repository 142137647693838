import api from 'services/system/user'

import { PermissionDescriptionEnum } from 'types/system/permission'
import { AutocompleteResult } from 'types/core/autocomplete'

export const userAutocomplete = async (text: string, permissions?: Array<PermissionDescriptionEnum>): Promise<Array<AutocompleteResult>> => {
  if (!text) return []

  let res = await api.userAutocomplete({ text, permissions })

  if (!res?.data?.success) return []

  return res?.data?.data
}
