import React from 'react'
import { Overlay } from 'react-md'
import { Route } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { useAuth } from 'contexts/auth/auth-context'
import Layout from 'components/layout/layout'
import LayoutError from 'components/layout/layout-error'
import Logo from 'components/core/logo'

import Home from 'pages/system/home'
import AuditRoutes from 'pages/audit/routes'
import AuthRoutes from 'pages/auth/routes'
import DeliveryRoutes from 'pages/delivery/routes'
import DevolutionRoutes from 'pages/devolution/routes'
import ExpeditionRoutes from 'pages/expedition/routes'
import InvoicingRoutes from 'pages/invoicing/routes'
import IntegrationRoutes from 'pages/integration/routes'
import OrderRoutes from 'pages/order/routes'
import PickupRoutes from 'pages/pickup/routes'
import QuoteRoutes from 'pages/quote/routes'
import SaleRoutes from 'pages/sale/routes'
import SystemRoutes from 'pages/system/routes'
import TicketRoutes from 'pages/ticket/routes'

const Routes: React.FC = () => {
  const { logged } = useAuth()

  return (
    <ErrorBoundary fallback={<LayoutError />}>
      {logged === null && (
        <Layout>
          <Overlay id='simple-overlay' visible={true} onRequestClose={() => {}}>
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <Logo />
            </div>
          </Overlay>
        </Layout>
      )}
      {logged === false && <AuthRoutes />}
      {logged === true && (
        <Layout>
          <AuditRoutes />
          <DeliveryRoutes />
          <DevolutionRoutes />
          <ExpeditionRoutes />
          <InvoicingRoutes />
          <IntegrationRoutes />
          <OrderRoutes />
          <PickupRoutes />
          <QuoteRoutes />
          <SaleRoutes />
          <SystemRoutes />
          <TicketRoutes />

          <Route path='/' exact component={Home} />
        </Layout>
      )}
    </ErrorBoundary>
  )
}

export default Routes
