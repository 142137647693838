import api from 'services/ticket/ticket'

export const handleSearch = async (request: any) => {
  let res = await api.searchByOrder(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/ticket/${id}/edit/client`)
}
