import React, { useEffect, useState } from 'react'
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'react-md'

import { CalloutType } from 'components/core/callout'
import { EmptyResultMessage } from 'types/core/api'
import { Callout } from 'components/core'

import { handleGetOrders } from './actions'

interface ComponentProps {
  packingListId: string
}

const Component: React.FC<ComponentProps> = ({ packingListId }) => {
  const [result, setResult] = useState<any>()

  useEffect(() => {
    if (packingListId) search(packingListId)

    return () => {}
  }, [packingListId])

  const search = async (packingListId: string) => setResult(await handleGetOrders(packingListId))

  return (
    <>
      {result && result.length > 0 ? (
        <Card fullWidth>
          <CardContent>
            <TableContainer>
              <Table fullWidth>
                <TableHeader>
                  <TableRow>
                    <TableCell>Núm. Pedido</TableCell>
                    <TableCell>Núm. NF</TableCell>
                    <TableCell>Destinatário</TableCell>
                    <TableCell>Tracking</TableCell>
                    <TableCell>Id Volume</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {result?.map((data: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{data.orderNumber}</TableCell>
                      <TableCell>{data.fiscalNoteNumber}</TableCell>
                      <TableCell>{data.recipientName}</TableCell>
                      <TableCell>{data.trackingCode}</TableCell>
                      <TableCell>{data.volumeId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        <Callout text={EmptyResultMessage} type={CalloutType.Info} />
      )}
    </>
  )
}

export default Component
