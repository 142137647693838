import React from 'react'
import { Grid } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'

import { handleSubmit, InputArea } from 'components/form'
import { Modal } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleAdd } from './actions'

interface ComponentProps {
  id: string
  disableModal: () => void
  load: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ id, disableModal, load }) => {
  const form = useForm({ defaultValues })

  return (
    <Modal
      title={'Adicionar Comentário'}
      visible={true}
      width={500}
      confirmTitle={'Adicionar'}
      confirmAction={async () =>
        await handleSubmit(form, validationSchema, async () => {
          if (await handleAdd({ ...form.getValues(), id: id })) {
            form.reset(form.getValues(), { dirty: false })
            disableModal()
            await load()
          }
        })
      }
      confirmDisabled={!form.formState.dirty}
      closeTitle={'Fechar'}
      closeAction={async () => disableModal()}>
      <FormContext {...form}>
        <Grid>
          <InputArea name='comment' label='Comentário *' rows={4} maxRows={6} />
        </Grid>
      </FormContext>
    </Modal>
  )
}

export default Component
