import api from 'services/devolution/devolution-packing-list'

export const handleSearch = async (request: any) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleEdit = (id: string) => {
  window.open(`/devolution/devolution-packing-list/${id}/edit`)
}
