import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getOrderFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (
    permissions?.order.fm.changePartnerLot ||
    permissions?.order.fm.updateStatus ||
    permissions?.order.fm.deleteLot ||
    permissions?.order.fm.print.contentDeclaration ||
    permissions?.order.fm.print.tag ||
    permissions?.order.fm.importCsv ||
    permissions?.order.fm.importManual ||
    permissions?.order.fm.importXml ||
    permissions?.order.log ||
    permissions?.order.fm.fixImportValidation ||
    permissions?.order.fm.search ||
    permissions?.order.fm.report.searchLot ||
    permissions?.order.fm.report.stoppedOrders ||
    permissions?.order.fm.report.performance ||
    permissions?.order.fm.report.incident
  ) {
    menu['/order'] = getMenu('/order', null, 'Pedidos', 'receipt', null)

    if (permissions?.order.fm.changePartnerLot)
      menu['/order/change-partner/lot'] = getMenu(
        '/order/change-partner/lot',
        '/order',
        'Atualização de Parceiro',
        'sync',
        '/order/change-partner/lot',
      )

    if (permissions?.order.fm.updateStatus)
      menu['/order/update-status/lot'] = getMenu(
        '/order/update-status/lot',
        '/order',
        'Atualização de Status',
        'move_down',
        '/order/update-status/lot',
      )

    if (permissions?.order.fm.importCsv || permissions?.order.fm.importManual || permissions?.order.fm.importXml) {
      menu['/order/import'] = getMenu('/order/import', '/order', 'Cadastro', 'upload_file', null)

      if (permissions?.order.fm.importCsv)
        menu['/order/import/csv'] = getMenu('/order/import/csv', '/order/import', 'Csv', 'description', '/order/import/csv')

      if (permissions?.order.fm.importManual)
        menu['/order/import/manual'] = getMenu('/order/import/manual', '/order/import', 'Manual', 'description', '/order/import/manual')

      if (permissions?.order.fm.importXml)
        menu['/order/import/xml'] = getMenu('/order/import/xml', '/order/import', 'Xml', 'description', '/order/import/xml')
    }

    if (permissions?.order.fm.deleteLot) menu['/order/delete/lot'] = getMenu('/order/delete/lot', '/order', 'Exclusão', 'delete', '/order/delete/lot')

    if (permissions?.order.fm.print.contentDeclaration || permissions?.order.fm.print.tag) {
      menu['/order/print'] = getMenu('/order/print', '/order', 'Imprimir', 'print', null)

      if (permissions?.order.fm.print.contentDeclaration)
        menu['/order/print/content-declaration'] = getMenu(
          '/order/print/content-declaration',
          '/order/print',
          'Declaração de Conteúdo',
          'receipt_long',
          '/order/print/content-declaration',
        )

      if (permissions?.order.fm.print.tag)
        menu['/order/print/tag'] = getMenu('/order/print/tag', '/order/print', 'Etiqueta', 'receipt_long', '/order/print/tag')
    }

    if (permissions?.order.log)
      menu['/order/log/search'] = getMenu('/order/log/search', '/order', 'Log de Exclusão', 'history_toggle_off', '/order/log/search')

    if (permissions?.order.fm.fixImportValidation)
      menu['/order/import/fix'] = getMenu('/order/import/fix', '/order', 'Pacotes Inválidos', 'build', '/order/import/fix')

    if (permissions?.order.fm.search) menu['/order/search'] = getMenu('/order/search', '/order', 'Pesquisa', 'search', '/order/search')

    if (
      permissions?.order.fm.report.searchLot ||
      permissions?.order.fm.report.stoppedOrders ||
      permissions?.order.fm.report.ordersWithProblems ||
      permissions?.order.fm.report.performance ||
      permissions?.order.fm.report.incident
    ) {
      menu['/order/report'] = getMenu('/order/report', '/order', 'Relatórios', 'print', null)

      if (permissions?.order.fm.report.searchLot)
        menu['/order/report/search-lot'] = getMenu(
          '/order/report/search-lot',
          '/order/report',
          'Busca Lote',
          'receipt_long',
          '/order/report/search-lot',
        )

      if (permissions?.order.fm.report.stoppedOrders)
        menu['/order/report/stopped-orders'] = getMenu(
          '/order/report/stopped-orders',
          '/order/report',
          'Pacotes Parados',
          'receipt_long',
          '/order/report/stopped-orders',
        )

      if (permissions?.order.fm.report.ordersWithProblems)
        menu['/order/report/orders-with-problems'] = getMenu(
          '/order/report/orders-with-problems',
          '/order/report',
          'Pedidos com Problema',
          'receipt_long',
          '/order/report/orders-with-problems',
        )

      if (permissions?.order.fm.report.performance)
        menu['/order/report/performance'] = getMenu(
          '/order/report/performance',
          '/order/report',
          'Performance',
          'receipt_long',
          '/order/report/performance',
        )

      if (permissions?.order.fm.report.incident)
        menu['/order/report/incident'] = getMenu('/order/report/incident', '/order/report', 'Sinistros', 'receipt_long', '/order/report/incident')
    }
  }
}
