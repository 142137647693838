import api from 'services/devolution/devolution-packing-list'

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handlePrint = async (id: string) => {
  let res = await api.print(id)

  return res?.data?.data
}
