import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid, Text } from 'react-md'

import { handleSubmit, InputArea, Select } from 'components/form'
import { TicketTypeOptions } from 'types/ticket/ticket-type'
import ButtonSuccess from 'components/core/button-success'
import { Cell, PageTitle } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'
import { toast } from 'react-toastify'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Abrir Ticket'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Abrir Ticket' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Select name='type' label='Tipo *' options={TicketTypeOptions} desktopSize={6} tabletSize={8} />
              </Grid>
              <Grid>
                <InputArea name='trackingCodes' label='Trackings *' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Para realizar a atualização informe no máximo de 100 trackings</i>
                  </Text>
                </Cell>
              </Grid>
              <Grid>
                <InputArea name='comment' label='Comentário' rows={4} maxRows={6} desktopSize={6} tabletSize={8} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () => {
                await handleSubmit(form, validationSchema, async () => {
                  let formValues = form.getValues()
                  let values: Array<string> = []

                  if (formValues.trackingCodes) {
                    let itens = formValues.trackingCodes as string
                    itens.split('\n').forEach((value: string) => {
                      if (value) values.push(value)
                    })

                    if (values.length > 100) {
                      toast.warn(`Não é permitido criar tickets para mais de 100 trackings`)
                      return
                    }

                    if (await handleSave({ type: formValues.type, comment: formValues.comment, trackingCodes: values }))
                      form.reset(defaultValues, { dirty: false })
                  }
                })
              }}
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
