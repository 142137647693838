import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getTicketClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.ticket.client) {
    menu['/ticket/client'] = getMenu('/ticket/client', null, 'Ticket', 'forum', null)

    menu['/ticket/new/client'] = getMenu('/ticket/new/client', '/ticket/client', 'Novo', 'add', '/ticket/new/client')

    menu['/ticket/search/client'] = getMenu('/ticket/search/client', '/ticket/client', 'Pesquisa', 'search', '/ticket/search/client')
  }
}
