import { trackPromise } from 'react-promise-tracker'

import { ApiResponse } from 'types/core/api'
import api from 'utils/api'

const url = '/v1/order/import-validation'
const Api = {
  searchImportValidationClient: (): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/search/client`)),
  searchImportValidationFm: (clientId: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${clientId}/search/fm`)),
  discardImportOrderClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/discard/client`, request)),
  discardImportOrderFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/discard/fm`, request)),
  fixImportOrderClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/fix/client`, request)),
  fixImportOrderFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/fix/fm`, request)),
}

export default Api
