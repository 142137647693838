import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, FileInput, Chip, FontIcon, Table, TableRow, TableCell, TableBody } from 'react-md'

import { ButtonEdit, ButtonSuccess, Callout, Cell, PageTitle } from 'components/core'
import ButtonError from 'components/core/button-error'

import { handleGetCsv, handleSave } from './actions'
import { CalloutType } from 'components/core/callout'

const Component: React.FC = () => {
  const [fileName, setFileName] = useState<string | null>()
  const [file, setFile] = useState<string | ArrayBuffer | null>()
  const [validations, setValidations] = useState<Array<string> | null>()

  useEffect(() => {
    document.title = 'Importação de Iss'

    return () => {}
  }, [])

  const getBase64 = (e: any) => {
    var file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader && reader.result) setFile(reader.result.toString().split(',')[1])
    }
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Importação de Iss' />
          <Card fullWidth>
            <CardContent>
              <Grid>
                <Cell>
                  <Chip>{fileName ? `Arquivo: ${fileName}` : 'Nenhum arquivo selecionado'}</Chip>
                </Cell>
                <Cell>
                  <FileInput
                    id='import-input'
                    theme={'secondary'}
                    themeType={'contained'}
                    buttonType={'text'}
                    accept={'.csv'}
                    icon={<FontIcon>description</FontIcon>}
                    disabled={fileName ? true : false}
                    onChange={event => {
                      if (event?.currentTarget?.files?.[0]) {
                        setFileName(event.currentTarget.files[0].name)
                        getBase64(event)
                      }
                    }}>
                    {'Selecionar Arquivo'}
                  </FileInput>
                  <ButtonSuccess
                    text='Importar'
                    disabled={fileName ? false : true}
                    icon={<FontIcon>check</FontIcon>}
                    onClick={async () => {
                      setValidations(await handleSave(fileName, file))
                      setFileName(null)
                      setFile(null)
                    }}
                  />
                  <ButtonError
                    text='Apagar'
                    disabled={fileName ? false : true}
                    icon={<FontIcon>cancel</FontIcon>}
                    onClick={async () => {
                      setFileName(null)
                      setFile(null)
                    }}
                  />
                  <ButtonEdit text='Download do Modelo' icon={<FontIcon>download</FontIcon>} onClick={async () => await handleGetCsv('Iss.csv')} />
                </Cell>
              </Grid>
            </CardContent>
          </Card>
        </Cell>

        {validations && (
          <>
            <Cell>
              <Callout type={CalloutType.Warning} text='Por favor corrija os erros informados abaixo e realize uma nova tentativa de importação' />
            </Cell>
            <Cell>
              <Card fullWidth>
                <CardContent>
                  <Table fullWidth>
                    <TableBody>
                      {validations.map((data: any, index) => (
                        <TableRow key={index}>
                          <TableCell>{data}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Cell>
          </>
        )}
      </Grid>
    </>
  )
}

export default Component
