import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  initialCreationDate: null,
  finalCreationDate: null,
  recipientName: null,
  trackingCode: null,
  orderNumber: null,
  order: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  initialCreationDate: Yup.date().nullable().required(),
  finalCreationDate: Yup.date().nullable().required(),
  recipientName: Yup.string().nullable(),
  trackingCode: Yup.string().nullable(),
  orderNumber: Yup.string().nullable(),
  order: Yup.string().nullable().required(),
})
