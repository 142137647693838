import { Table, TableBody, TableCell, TableRow } from 'react-md'

import { Cell } from 'components/core'

import CountPercentage from './components/count-percentage'
import CountWithPercentage from './components/count-with-percentage'
import CountWithoutPercentage from './components/count-without-percentage'

interface CountProps {
  result: any
}

const Component = ({ result }: CountProps) => (
  <Cell>
    <Table disableHover disableBorders fullWidth>
      <TableBody lineWrap>
        <TableRow>
          <TableCell style={{ width: '50%' }} disablePadding>
            <CountPercentage color='#3f51b5' text='Total de Volumes' total={result.totalOrders} />
          </TableCell>
          <TableCell style={{ width: '50%' }} disablePadding>
            <CountWithoutPercentage color='#673ab7' text='Total SLA' percent={result.slaInPercentage} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <Table disableHover disableBorders fullWidth>
      <TableBody lineWrap>
        <TableRow>
          <TableCell disablePadding>
            <CountWithPercentage color='#009688' text='Entregas no Prazo' total={result.totalDeliveredOnTime} percent={result.totalDeliveredOnTime} />
          </TableCell>
          <TableCell disablePadding>
            <CountWithPercentage
              color='#ff9800'
              text='Entregas com Atraso'
              total={result.totalDeliveredLate}
              percent={result.deliveredOnLateInPercentage}
            />
          </TableCell>
          <TableCell disablePadding>
            <CountWithPercentage color='#795548' text='Devoluções' total={result.totalDevolution} percent={result.devolutionInPercentage} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell disablePadding>
            <CountWithPercentage color='#f4511e' text='Sinistros' total={result.totalIncident} percent={result.incidentInPercentage} />
          </TableCell>
          <TableCell disablePadding>
            <CountWithPercentage
              color='#4db6ac'
              text='Em Andamento no Prazo'
              total={result.totalInProgressOnTime}
              percent={result.inProgressOnTimeInPercentage}
            />
          </TableCell>
          <TableCell disablePadding>
            <CountWithPercentage
              color='#ffb74d'
              text='Em Andamento com Atraso'
              total={result.totalInProgressLate}
              percent={result.inProgressLateInPercentage}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Cell>
)

export default Component
