import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'

import { ConfirmDialog, Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { handleSubmit, Input, InputDocument } from 'components/form'
import { DataTable, EditButton, DeleteButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'
import { formatDocument } from 'components/form/types'

import { handleSearch, handleEdit, handleDelete } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })
  const [deleteId, setDeleteId] = useState<string>()
  const [toogleUpdate, setToogleUpdate] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Pesquisa de Cliente'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Cliente' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Input name='name' label='Nome' desktopSize={9} tabletSize={4} />
                  <InputDocument name='document' label='Cpf/Cnpj' maxLength={18} desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[
              { order: 'FantasyName', label: 'Nome Fantasia' },
              { order: 'Document', label: 'Cpf/Cnpj' },
              { label: 'Grupo Empresarial' },
              { label: '' },
            ]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            update={toogleUpdate}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{formatDocument(data.document)}</TableCell>
                  <TableCell>{data.businessGroupName}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                    <DeleteButton id={`delete-${index}`} action={() => setDeleteId(data.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
      <ConfirmDialog
        text='Deseja realmente excluir o cliente? Essa ação não poderá ser desfeita'
        visible={deleteId ? true : false}
        confirmAction={async () => {
          setDeleteId(undefined)
          if (deleteId) if (await handleDelete(deleteId)) setToogleUpdate(!toogleUpdate)
        }}
        cancelAction={async () => setDeleteId(undefined)}
      />
    </>
  )
}

export default Component
