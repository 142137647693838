import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

import { getDeliveryMenu } from './components/delivery/delivery-menu'
import { getDevolutionMenu } from './components/devolution/devolution-menu'
import { getExpeditionFmMenu } from './components/expedition/expedition-fm-menu'
import { getIntegrationClientMenu } from './components/integration/integration-client-menu'
import { getInvoicingClientMenu } from './components/invoicing/invoicing-client-menu'
import { getInvoicingFmMenu } from './components/invoicing/invoicing-fm-menu'
import { getOrderClientMenu } from './components/order/order-client-menu'
import { getOrderFmMenu } from './components/order/order-fm-menu'
import { getPickupClientMenu } from './components/pickup/pickup-client-menu'
import { getPickupFmMenu } from './components/pickup/pickup-fm-menu'
import { getQuoteClientMenu } from './components/quote/quote-client-menu'
import { getQuoteFmMenu } from './components/quote/quote-fm-menu'
import { getRegisterMenu } from './components/register/register-menu'
import { getSystemFmMenu } from './components/system/system-fm-menu'
import { getTicketClientMenu } from './components/ticket/ticket-client-menu'
import { getTicketFmMenu } from './components/ticket/ticket-fm-menu'

interface BodyProps {
  menuPermissions: MenuPermission
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getBody = ({ menuPermissions, getMenu }: BodyProps) => {
  const body: LayoutNavigationTree<LayoutNavigationItem> = {}

  getRegisterMenu({ permissions: menuPermissions, menu: body, getMenu })
  getPickupClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getPickupFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getIntegrationClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getQuoteClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getQuoteFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getDevolutionMenu({ permissions: menuPermissions, menu: body, getMenu })
  getDeliveryMenu({ permissions: menuPermissions, menu: body, getMenu })
  getExpeditionFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getInvoicingClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getInvoicingFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getOrderClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getTicketClientMenu({ permissions: menuPermissions, menu: body, getMenu })
  getOrderFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getTicketFmMenu({ permissions: menuPermissions, menu: body, getMenu })
  getSystemFmMenu({ permissions: menuPermissions, menu: body, getMenu })

  return body
}
