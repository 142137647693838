export interface PrintContentDeclarationFmOrderList {
  value: PrintContentDeclarationFmOrderEnum | null
  label: string
}

export enum PrintContentDeclarationFmOrderEnum {
  CreationDate = 'CreationDate',
  RecipientName = 'RecipientName',
  OrderNumber = 'OrderNumber',
}

export const PrintContentDeclarationFmOrderOptions: Array<PrintContentDeclarationFmOrderList> = [
  { value: PrintContentDeclarationFmOrderEnum.CreationDate, label: 'Data de Criação' },
  { value: PrintContentDeclarationFmOrderEnum.RecipientName, label: 'Nome do Destinatário' },
  { value: PrintContentDeclarationFmOrderEnum.OrderNumber, label: 'Núm. Pedido' },
]
