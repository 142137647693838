import React, { useEffect, useRef, useState } from 'react'
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  FontIcon,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Select as TemplateSelect,
  ListboxOption,
} from 'react-md'
import { useReactToPrint } from 'react-to-print'
import { FormContext, useForm } from 'react-hook-form'
import { format, parseISO } from 'date-fns'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonEdit, ButtonSearch, ButtonClearSearch, Callout } from 'components/core'
import { handleSubmit, Input, InputDatePicker, Select } from 'components/form'
import { ClientAutoComplete } from 'components/auto-complete'
import { getStatusDescription } from 'types/order/status'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'
import { EmptyResultMessage } from 'types/core/api'

import { PrintTagFmOrderEnum, PrintTagFmOrderOptions } from './types'
import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'

import Print from './components/print'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [pages, setPages] = useState<Array<any>>([])
  const [page, setPage] = useState<number>(0)
  const [orders, setOrders] = useState<Array<any>>()
  const componentRef = useRef(null)
  const order = form.watch('order')

  const PageOptions: Array<ListboxOption> = pages.map((x, i) => {
    return { value: i, label: `Página ${i + 1} - ${x.length} volumes(s)` }
  })

  useEffect(() => {
    document.title = 'Impressão de Etiqueta'

    return () => {}
  }, [])

  useEffect(() => {
    if (!order) form.setValue<string, string>('order', PrintTagFmOrderEnum.OrderNumber)
  }, [order])

  const load = async () => {
    const res = await handleSearch(form.getValues())

    if (res.length > 0) {
      var ordersByPage = ordersByPages(res, 500)
      update(ordersByPage, ordersByPage[0], 0)
    } else {
      toast.info(EmptyResultMessage)
      setOrders([])
    }
  }

  const removeOrder = (orderId: string) => {
    pages[page] = pages[page]?.filter((x: any) => x.orderId !== orderId)
    if (pages[page].length === 0) {
      var pagesFiltered = pages.filter(x => x.length > 0)
      update(pagesFiltered, pagesFiltered[0], 0)
    } else {
      update(pages, pages[page], page)
    }
  }

  const update = (pages: Array<Array<any>>, orders: Array<any>, page: number) => {
    setPages(pages)
    setOrders(orders)
    setPage(page)
  }

  const ordersByPages = (arr: Array<any>, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size))

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Etiqueta' })

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Impressão de Etiqueta' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={6} />
                  <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial *' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalCreationDate' label='Data de Criação Final *' desktopSize={3} tabletSize={4} />
                  <Input name='recipientName' label='Destinatário' desktopSize={6} tabletSize={4} />
                  <Input name='orderNumber' label='Núm. Pedido' desktopSize={3} tabletSize={4} />
                  <Input name='fiscalNoteNumber' label='Núm. Nota Fiscal' desktopSize={3} tabletSize={4} />
                  <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />
                  <Select name='order' label='Ordem *' options={PrintTagFmOrderOptions} desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => await load())} />
              <ButtonEdit
                text='Imprimir'
                icon={<FontIcon>print</FontIcon>}
                disabled={!orders || orders.length === 0}
                onClick={async () => handlePrintHTML()}
              />
              <ButtonClearSearch
                disabled={!form.formState.dirty}
                onClick={async () => {
                  form.reset(defaultValues, { dirty: false })
                }}
              />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          {orders?.length! > 0 && (
            <Card fullWidth>
              <CardContent>
                <TableContainer style={{ maxHeight: '25rem' }}>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Data de Criação</TableCell>
                        <TableCell>Remetente</TableCell>
                        <TableCell>Nota Fiscal</TableCell>
                        <TableCell>Pedido</TableCell>
                        <TableCell>Volume</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Destinatário</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {orders?.map((data: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy")}</TableCell>
                          <TableCell>{data.clientFantasyName}</TableCell>
                          <TableCell>{data.fiscalNoteNumber}</TableCell>
                          <TableCell>{data.orderNumber}</TableCell>
                          <TableCell>{data.volume}</TableCell>
                          <TableCell>{getStatusDescription(data.status)}</TableCell>
                          <TableCell>{data.recipientName}</TableCell>
                          <TableCell align='right'>
                            <DeleteButton id={`delete-${index}`} action={() => removeOrder(data.orderId)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                  <TemplateSelect
                    id='select-pagination'
                    label='Paginação'
                    theme={'filled'}
                    options={PageOptions}
                    value={page.toString()}
                    onChange={value => update(pages, pages[parseInt(value)], parseInt(value))}
                    disableMovementChange={true}
                    style={{ width: 250, marginTop: 10 }}
                  />
                </div>
              </CardContent>
            </Card>
          )}

          {orders?.length === 0 && <Callout text={EmptyResultMessage} type={CalloutType.Info} />}
        </Cell>
      </Grid>

      {orders && orders.length > 0 && (
        <div style={{ display: 'none' }}>
          <Print orders={orders} ref={componentRef} />
        </div>
      )}
    </>
  )
}

export default Component
