import Yup from 'utils/yup'

export const issuerDefaultValues = {
  businessGroup: {
    id: null,
  },
  fantasyName: null,
  companyName: null,
  document: null,
  ie: null,
}

export const issuerValidationSchema = Yup.object().shape({
  businessGroup: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  fantasyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  companyName: Yup.string().nullable().required().max(60, 'O nome fantasia deve ter no máximo 60 caracteres'),
  document: Yup.string().nullable().required().max(19, 'O cpf/cnpj deve ter no máximo 19 caracteres'),
  ie: Yup.string().nullable().required().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
})
