import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  maximumWeightVolume: null,
  biggestSide: null,
  sumSides: null,
  cubageFactor: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().nullable().max(60, 'O nome deve ter no máximo 60 caracteres'),
  maximumWeightVolume: Yup.number().nullable().required(),
  biggestSide: Yup.number().nullable().required(),
  sumSides: Yup.number().nullable().required(),
  cubageFactor: Yup.number()
    .nullable()
    .required()
    .min(3333, 'O fator de cubagem deve ser de no mínimo 3333')
    .max(6000, 'O fator de cubagem deve ser de no máximo 6000'),
})
