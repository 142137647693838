import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'
import { Grid } from 'react-md'

import { getPartnerIntegrationDescription } from 'types/quote/partner-integration'
import { Input, InputNumber } from 'components/form'
import { TextLabel } from 'components/label'

interface ComponentProps {
  form: FormContextValues<any>
  partner: any
}

const Component: React.FC<ComponentProps> = ({ form, partner }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Input name='name' label='Nome *' desktopSize={4} />
        <TextLabel
          name='integration'
          label='Integração *'
          value={getPartnerIntegrationDescription(partner.integration)}
          desktopSize={4}
          tabletSize={4}
        />
      </Grid>
      <Grid>
        <InputNumber name='maximumWeightVolume' label='Peso Máximo por Volume *' desktopSize={2} tabletSize={4} />
        <InputNumber name='biggestSide' label='Maior Lado *' desktopSize={2} tabletSize={4} />
        <InputNumber name='sumSides' label='Soma dos Lados *' desktopSize={2} tabletSize={4} />
        <InputNumber name='cubageFactor' label='Fator de Cubagem *' desktopSize={2} tabletSize={4} />
      </Grid>
    </FormContext>
  )
}

export default Component
