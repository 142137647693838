import React, { useEffect, useState } from 'react'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, TableContainer, Table, TableHeader, TableBody, useToggle } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSearch, Callout, ConfirmDialog } from 'components/core'
import { DeleteButton, EditButton } from 'components/data-table'
import { getCteTypeDescription } from 'types/invoicing/cte-type'
import { CalloutType } from 'components/core/callout'
import { EmptyResultMessage } from 'types/core/api'

import { handleSearch, handleDiscard } from './actions'

import FixImportValidation from './fix'

const Component: React.FC = () => {
  const [cte, setCte] = useState<any>()
  const [ctes, setCtes] = useState<any>()
  const [visibleFixModal, enableFixModal, disableFixModal] = useToggle(false)
  const [visibleDeleteModal, enableDeleteModal, disableDeleteModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Ctes Inválidos'

    return () => {}
  }, [])

  const search = async () => {
    let res = await handleSearch()
    if (res?.length === 0) toast.info(EmptyResultMessage)
    setCtes(res)
  }

  const discard = async (trackingCode: string, type: string) => {
    if (await handleDiscard({ trackingCode: trackingCode, type: type })) search()
  }

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Ctes Inválidos' />
          <Card fullWidth>
            <CardContent>
              <Callout
                text='Serão carregados 100 ctes por vez e serão mantidos no cache por 1h, após expiração do cache ou tratamento dos ctes listados a lista será atualizada'
                type={CalloutType.Info}
              />
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await search()} />
            </CardActions>
          </Card>
        </Cell>
        {ctes?.length === 0 && (
          <Cell>
            <Callout text={EmptyResultMessage} type={CalloutType.Info} />
          </Cell>
        )}
        {ctes?.length > 0 && (
          <Cell>
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Tracking</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {ctes.map((data: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{data.trackingCode}</TableCell>
                          <TableCell>{getCteTypeDescription(data.type)}</TableCell>
                          <TableCell align='right'>
                            <EditButton
                              id={`edit-${index}`}
                              tooltip={'Emitir Cte'}
                              action={() => {
                                setCte(data)
                                enableFixModal()
                              }}
                            />
                            <DeleteButton
                              id={`edit-${index}`}
                              tooltip={'Excluir Cte'}
                              action={() => {
                                setCte(data)
                                enableDeleteModal()
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Cell>
        )}
      </Grid>

      <FixImportValidation
        cte={cte}
        visibleModal={visibleFixModal}
        disableModal={() => disableFixModal()}
        onSuccess={() => {
          setCte(null)
          search()
        }}
      />

      <ConfirmDialog
        text='Deseja realmente excluir o cte? Essa ação não poderá ser desfeita'
        visible={visibleDeleteModal}
        confirmAction={async () => {
          setCte(undefined)
          disableDeleteModal()
          if (cte) await discard(cte.trackingCode, cte.type)
        }}
        cancelAction={async () => {
          setCte(undefined)
          disableDeleteModal()
        }}
      />
    </>
  )
}

export default Component
