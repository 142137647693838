export const getTicketTypeDescription = (status: string): string => {
  return TicketTypeOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface TicketTypeList {
  value: TicketTypeEnum | null
  label: string
}

export enum TicketTypeEnum {
  Lateness = 'Lateness',
  ContestDelivery = 'ContestDelivery',
  ChangeAddress = 'ChangeAddress',
  SuspendDelivery = 'SuspendDelivery',
  RetrySameAddress = 'RetrySameAddress',
}

export const TicketTypeOptions: Array<TicketTypeList> = [
  { value: null, label: '' },
  { value: TicketTypeEnum.ChangeAddress, label: 'Alterar Endereço' },
  { value: TicketTypeEnum.Lateness, label: 'Atraso' },
  { value: TicketTypeEnum.ContestDelivery, label: 'Contestar Entrega' },
  { value: TicketTypeEnum.RetrySameAddress, label: 'Nova Tentativa (Mesmo Endereço)' },
  { value: TicketTypeEnum.SuspendDelivery, label: 'Suspender Entrega' },
]
