import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getInvoicingClientMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.invoicing.client) {
    menu['/invoicing/client'] = getMenu('/invoicing/client', null, 'Faturamento', 'wallet', null)

    menu['/invoicing/report/client'] = getMenu('/invoicing/report/client', '/invoicing/client', 'Relatórios', 'print', null)
    menu['/invoicing/report/invoicing-preview/client'] = getMenu(
      '/invoicing/report/invoicing-preview/client',
      '/invoicing/report/client',
      'Prévia de Faturamento',
      'receipt_long',
      '/invoicing/report/invoicing-preview/client',
    )
  }
}
