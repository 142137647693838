import Yup from 'utils/yup'

export const otherRulesDefaultValues = {
  loggiAllowAirTransport: null,
  booksIcmsExemption: null,
  correiosRedispatch: null,
  fmTemplateTrackingEmails: null,
  cubageExemption: null,
}

export const otherRulesValidationSchema = Yup.object().shape({
  loggiAllowAirTransport: Yup.boolean().nullable().required(),
  booksIcmsExemption: Yup.boolean().nullable().required(),
  correiosRedispatch: Yup.boolean().nullable().required(),
  fmTemplateTrackingEmails: Yup.boolean().nullable().required(),
  cubageExemption: Yup.number().nullable().required().min(1, 'Isenção de cubagem inválida'),
})
