import api from 'services/invoicing/cte'

export const handleSearch = async () => {
  let res = await api.searchCreateCte()

  return res?.data?.data
}

export const handleDiscard = async (values: any) => {
  let res = await api.discardCreateCte(values)

  return res?.data?.success
}
