import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import TicketEditClient from 'pages/ticket/client/ticket/edit'
import TicketNewClient from 'pages/ticket/client/ticket/new'
import TicketSearchClient from 'pages/ticket/client/ticket/search'

import TicketEditFm from 'pages/ticket/fm/ticket/edit'
import TicketSearchFm from 'pages/ticket/fm/ticket/search'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.ticket.client && (
        <>
          <Route path='/ticket/:id/edit/client' exact component={TicketEditClient} />
          <Route path='/ticket/new/client' exact component={TicketNewClient} />
          <Route path='/ticket/search/client' exact component={TicketSearchClient} />
        </>
      )}

      {menuPermission?.ticket.fm && (
        <>
          <Route path='/ticket/:id/edit/fm' exact component={TicketEditFm} />
          <Route path='/ticket/search/fm' exact component={TicketSearchFm} />
        </>
      )}
    </>
  )
}

export default Routes
