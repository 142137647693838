import React from 'react'

import { PermissionDescriptionEnum } from 'types/system/permission'
import { InputAutoComplete } from 'components/form'

import { userAutocomplete } from './actions'

interface ComponentProps {
  name: string
  label: string
  desktopSize?: number
  tabletSize?: number
  disabled?: boolean
  permissions?: Array<PermissionDescriptionEnum>
  [x: string]: any
}

const Component: React.FC<ComponentProps> = ({ name, label, desktopSize, tabletSize, permissions, ...rest }) => {
  return (
    <InputAutoComplete
      name={name}
      label={label}
      searchData={async text => await userAutocomplete(text, permissions)}
      desktopSize={desktopSize}
      tabletSize={tabletSize}
      {...rest}
    />
  )
}

export default Component
