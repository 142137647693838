import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { handleSubmit, InputDatePicker } from 'components/form'
import { ClientAutoComplete } from 'components/auto-complete'

import { defaultValues, validationSchema } from './config'
import { handleReport } from './actions'

import PerformanceCounts from './components/performance-counts'
import PerformanceTable from './components/performance-table'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [result, setResult] = useState<any>()

  useEffect(() => {
    document.title = 'Performance'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Performance' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={3} />
                  <InputDatePicker name='initialPeriod' label='Período Inicial *' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalPeriod' label='Período Final *' desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    var res = await handleReport(form.getValues())
                    if (res) setResult(res)
                  })
                }
              />
              <ButtonClearSearch
                disabled={!form.formState.dirty}
                onClick={async () => {
                  form.reset(defaultValues, { dirty: false })
                  setResult(null)
                }}
              />
            </CardActions>
          </Card>
        </Cell>

        {result && (
          <>
            <PerformanceCounts result={result} />
            <PerformanceTable states={result.states} />
          </>
        )}
      </Grid>
    </>
  )
}

export default Component
