import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { BusinessGroupAutoComplete } from 'components/auto-complete'
import { InputDocument, Input } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Emissor da Nota Fiscal
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <FormContext {...form}>
            <BusinessGroupAutoComplete name='businessGroup' label='Grupo Empresarial *' desktopSize={6} tabletSize={8} />
            <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
            <Input name='ie' label='Inscrição Estadual *' desktopSize={3} tabletSize={4} />
            <Input name='fantasyName' label='Nome Fantasia *' desktopSize={6} tabletSize={4} />
            <Input name='companyName' label='Razão Social *' desktopSize={6} tabletSize={4} />
          </FormContext>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
