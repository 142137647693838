import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { handleSubmit, Input, InputDatePicker, Select, SelectBoolean } from 'components/form'
import { getTicketStatusDescription, TicketStatusOptions } from 'types/ticket/ticket-status'
import { getTicketTypeDescription, TicketTypeOptions } from 'types/ticket/ticket-type'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { ClientAutoComplete, UserAutoComplete } from 'components/auto-complete'
import { PermissionDescriptionEnum } from 'types/system/permission'
import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'

import { defaultValues, validationSchema } from './config'
import { handleEdit, handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Ticket'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Ticket' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={6} />
                  <UserAutoComplete name='responsible' label='Responsável' permissions={[PermissionDescriptionEnum.Sac]} desktopSize={6} />
                  <Select name='status' label='Status' options={TicketStatusOptions} desktopSize={3} tabletSize={4} />
                  <Select name='type' label='Tipo' options={TicketTypeOptions} desktopSize={3} tabletSize={4} />
                  <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />
                  <SelectBoolean name='assigned' label='Atribuído' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalCreationDate' label='Data de Criação Final' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='initialReturnDate' label='Data de Retorno Inicial' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalReturnDate' label='Data de Retorno Final' desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[
              { label: 'Número' },
              { label: 'Data de Abertura' },
              { label: 'Remetente' },
              { label: 'Tipo' },
              { label: 'Status' },
              { label: 'Tracking' },
              { label: 'Responsável' },
              { label: 'Data de Retorno' },
              { label: '' },
            ]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.id}</TableCell>
                  <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy")}</TableCell>
                  <TableCell>{data.clientFantasyName}</TableCell>
                  <TableCell>{getTicketTypeDescription(data.type)}</TableCell>
                  <TableCell>{getTicketStatusDescription(data.status)}</TableCell>
                  <TableCell>{data.trackingCode}</TableCell>
                  <TableCell>{data.responsibleName}</TableCell>
                  <TableCell>{data.returnDate ? format(parseISO(data.returnDate), "dd'/'MM'/'yyyy") : ''}</TableCell>
                  <TableCell align='right'>
                    <EditButton id={`edit-ticket-${index}`} action={() => handleEdit(data.externalId)} />
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
    </>
  )
}

export default Component
