import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, FileInput, Chip, FontIcon, Text } from 'react-md'

import { ButtonEdit, ButtonSuccess, Cell, PageTitle } from 'components/core'
import ButtonError from 'components/core/button-error'

import { handleGetCsv, handleImport } from './actions'

const Component: React.FC = () => {
  const [fileName, setFileName] = useState<string | null>()
  const [file, setFile] = useState<string | ArrayBuffer | null>()

  useEffect(() => {
    document.title = 'Atualização de Status'

    return () => {}
  }, [])

  const getBase64 = (e: any) => {
    var file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader && reader.result) setFile(reader.result.toString().split(',')[1])
    }
  }

  return (
    <Grid>
      <Cell>
        <PageTitle title='Atualização de Status' />
        <Card fullWidth>
          <CardContent>
            <Grid>
              <Cell>
                <Chip>{fileName ? `Arquivo: ${fileName}` : 'Nenhum arquivo selecionado'}</Chip>
              </Cell>
              <Cell>
                <FileInput
                  id='import-input'
                  theme={'secondary'}
                  themeType={'contained'}
                  buttonType={'text'}
                  accept={'.csv'}
                  icon={<FontIcon>description</FontIcon>}
                  disabled={fileName ? true : false}
                  onChange={event => {
                    if (event?.currentTarget?.files?.[0]) {
                      setFileName(event.currentTarget.files[0].name)
                      getBase64(event)
                    }
                  }}>
                  {'Selecionar Arquivo'}
                </FileInput>
                <ButtonSuccess
                  text='Atualizar'
                  disabled={fileName ? false : true}
                  icon={<FontIcon>check</FontIcon>}
                  onClick={async () => {
                    if (await handleImport({ fileName, file })) {
                      setFileName(null)
                      setFile(null)
                    }
                  }}
                />
                <ButtonError
                  text='Apagar'
                  disabled={fileName ? false : true}
                  icon={<FontIcon>cancel</FontIcon>}
                  onClick={async () => {
                    setFileName(null)
                    setFile(null)
                  }}
                />
                <ButtonEdit text='Download do Modelo' icon={<FontIcon>download</FontIcon>} onClick={async () => await handleGetCsv('Pedidos.csv')} />
              </Cell>
              <Cell>
                <Text type='caption' component='h1'>
                  <i>Para realizar a atualização informe um arquivo com até no máximo 500kb de tamanho</i>
                </Text>
              </Cell>
            </Grid>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
