import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow } from 'react-md'

import { handleSubmit, InputDatePicker, InputNumber, SelectBoolean } from 'components/form'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { DataTable, EditButton } from 'components/data-table'
import { DataTableResult } from 'components/data-table/types'

import { handleSearch, handleEdit } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Romaneio de Devolução'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Romaneio de Devolução' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalCreationDate' label='Data de Criação Final' desktopSize={3} tabletSize={4} />
                <InputNumber name='number' label='Número' desktopSize={2} tabletSize={2} />
                <SelectBoolean name='released' label='Liberado' desktopSize={2} tabletSize={3} />
                <SelectBoolean name='finished' label='Finalizado' desktopSize={2} tabletSize={3} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { order: 'id', label: 'Número' },
            { label: 'Código' },
            { label: 'Portador' },
            { label: 'Liberado' },
            { label: 'Finalizado' },
            { label: '' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{data.number}</TableCell>
                <TableCell>{data.code}</TableCell>
                <TableCell>{data.carrierName}</TableCell>
                <TableCell>{data.released ? 'Sim' : 'Não'}</TableCell>
                <TableCell>{data.finished ? 'Sim' : 'Não'}</TableCell>
                <TableCell align='right'>
                  <EditButton id={`edit-${index}`} action={() => handleEdit(data.id)} />
                </TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
