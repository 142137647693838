import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/devolution-packing-list'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  getOrders: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/orders`)),
  print: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}/print`)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
}

export default Api
