import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleReport } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Pedidos Com Problema'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pedidos Com Problema' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente' desktopSize={3} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  await handleReport(form.getValues())
                  form.reset(defaultValues, { dirty: false })
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
