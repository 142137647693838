export interface PermissionOption {
  value: PermissionEnum
  label: string
}

export enum PermissionEnum {
  None = '0',
  Administrator = '2',
  Coordinator = '3',
  Expedition = '4',
  Sac = '5',
  Sales = '40',
  Client = '50',
}

export enum PermissionDescriptionEnum {
  System = 'System',
  Administrator = 'Administrator',
  Coordinator = 'Coordinator',
  Expedition = 'Expedition',
  Sac = 'Sac',
  Sales = 'Sales',
  Client = 'Client',
}

export const PermissionOptions: Array<PermissionOption> = [
  { value: PermissionEnum.None, label: 'Selecione a permissão' },
  { value: PermissionEnum.Administrator, label: 'Administrador' },
  { value: PermissionEnum.Client, label: 'Cliente' },
  { value: PermissionEnum.Coordinator, label: 'Coordenação' },
  { value: PermissionEnum.Expedition, label: 'Expedição' },
  { value: PermissionEnum.Sac, label: 'Sac' },
  { value: PermissionEnum.Sales, label: 'Vendas' },
]
