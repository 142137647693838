import Yup from 'utils/yup'

export const defaultValues = {
  type: null,
  trackingCodes: '',
  comment: null,
}

export const validationSchema = Yup.object().shape({
  type: Yup.string().nullable().required(),
  trackingCodes: Yup.string().nullable().required(),
  comment: Yup.string().nullable().max(300, 'O comentário deve ter no máximo 300 caracteres'),
})
