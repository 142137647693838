export const getCteTypeDescription = (status: string): string => {
  return CteTypeOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface CteTypeList {
  value: CteTypeEnum | null
  label: string
}

export enum CteTypeEnum {
  Delivery = 'Delivery',
  Devolution = 'Devolution',
}

export const CteTypeOptions: Array<CteTypeList> = [
  { value: null, label: '' },
  { value: CteTypeEnum.Delivery, label: 'Entrega' },
  { value: CteTypeEnum.Devolution, label: 'Devolução' },
]
