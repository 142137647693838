import { toast } from 'react-toastify'

import { handleValidate } from 'components/form/form-submit'
import { ValidationMessage } from 'types/core/api'
import { FormList } from 'types/core/form'
import api from 'services/system/company'

export const handleValidateForms = async (form: FormList, addressForm: FormList): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(form.form, form.validationSchema))) success = false
  if (!(await handleValidate(addressForm.form, addressForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleGet = async (id: string) => {
  let res = await api.get(id)

  return res?.data?.data
}

export const handleSave = async (values: any) => {
  let res = await api.update(values)

  return res?.data?.success
}
