import { MenuPermission } from 'components/layout/components/menu/types'
import { PermissionDescriptionEnum } from 'types/system/permission'

export interface UserData {
  id: string
  name: string
  permissions: Array<string>
}

export interface AuthContextData {
  logged: boolean | null
  user: UserData | null
  theme: Theme | null
  login(values: LoginRequest): Promise<void>
  logout(): void
  setTheme: React.Dispatch<React.SetStateAction<Theme | null>>
  getUserPermission(): Array<PermissionDescriptionEnum>
  getMenuPermission(): MenuPermission
}

export interface LoginRequest {
  login: string
  password: string
}

export enum Theme {
  Light = 'Light',
  Dark = 'Dark',
}
