import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  initialCreationDate: null,
  finalCreationDate: null,
  type: null,
  status: null,
  trackingCode: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable(),
  }),
  initialCreationDate: Yup.date().nullable(),
  finalCreationDate: Yup.date().nullable(),
  type: Yup.string().nullable(),
  status: Yup.string().nullable(),
  trackingCode: Yup.string().nullable().max(30, 'O tracking deve ter no máximo 30 caracteres'),
})
