import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getRegisterMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (
    permissions.sale.client.client ||
    permissions.sale.client.search ||
    permissions.system.company ||
    permissions.system.holiday ||
    permissions.sale.businessGroup ||
    permissions.system.ibge ||
    permissions.quote.partner.partner ||
    permissions.quote.partner.searchCoverage ||
    permissions.quote.partner.searchFare ||
    permissions.system.profile ||
    permissions.system.carrier ||
    permissions.pickup.pickupRoute ||
    permissions.system.user
  ) {
    menu['/register'] = getMenu('/register', null, 'Cadastros', 'manage_accounts', null)

    if (permissions.sale.client.client || permissions.sale.client.search) {
      menu['/sale/client'] = getMenu('/sale/client', '/register', 'Cliente', 'person', null)

      if (permissions.sale.client.client) {
        menu['/sale/client/new'] = getMenu('/sale/client/new', '/sale/client', 'Novo', 'add', '/sale/client/new')
      }

      if (permissions.sale.client.search) {
        menu['/sale/client/search'] = getMenu('/sale/client/search', '/sale/client', 'Pesquisa', 'search', '/sale/client/search')
      }
    }

    if (permissions.system.company) {
      menu['/system/company'] = getMenu('/system/company', '/register', 'Empresa', 'business', null)
      menu['/system/company/new'] = getMenu('/system/company/new', '/system/company', 'Nova', 'add', '/system/company/new')
      menu['/system/company/search'] = getMenu('/system/company/search', '/system/company', 'Pesquisa', 'search', '/system/company/search')
    }

    if (permissions.system.holiday) {
      menu['/system/holiday'] = getMenu('/system/holiday', '/register', 'Feriado', 'calendar_month', null)
      menu['/system/holiday/new'] = getMenu('/system/holiday/new', '/system/holiday', 'Novo', 'add', '/system/holiday/new')
      menu['/system/holiday/search'] = getMenu('/system/holiday/search', '/system/holiday', 'Pesquisa', 'search', '/system/holiday/search')
    }

    if (permissions.sale.businessGroup) {
      menu['/sale/business-group'] = getMenu('/sale/business-group', '/register', 'Grupo Empresarial', 'card_travel', null)
      menu['/sale/business-group/new'] = getMenu('/sale/business-group/new', '/sale/business-group', 'Novo', 'add', '/sale/business-group/new')
      menu['/sale/business-group/search'] = getMenu(
        '/sale/business-group/search',
        '/sale/business-group',
        'Pesquisa',
        'search',
        '/sale/business-group/search',
      )
    }

    if (permissions.system.ibge) {
      menu['/system/ibge'] = getMenu('/system/ibge', '/register', 'Ibge', 'topic', null)
      menu['/system/ibge/import'] = getMenu('/system/ibge/import', '/system/ibge', 'Importar', 'add', '/system/ibge/import')
      menu['/system/ibge/search'] = getMenu('/system/ibge/search', '/system/ibge', 'Pesquisa', 'search', '/system/ibge/search')
    }

    if (permissions.quote.partner.partner || permissions.quote.partner.searchCoverage || permissions.quote.partner.searchFare) {
      menu['/quote/partner'] = getMenu('/quote/partner', '/register', 'Parceiro', 'handshake', null)

      if (permissions.quote.partner.partner) {
        menu['/quote/partner/new'] = getMenu('/quote/partner/new', '/quote/partner', 'Novo', 'add', '/quote/partner/new')
        menu['/quote/partner/search'] = getMenu('/quote/partner/search', '/quote/partner', 'Pesquisa', 'search', '/quote/partner/search')
      }

      if (permissions.quote.partner.searchCoverage) {
        menu['/quote/partner/search-coverage'] = getMenu(
          '/quote/partner/search-coverage',
          '/quote/partner',
          'Pesquisa de Abrangência',
          'travel_explore',
          '/quote/partner/search-coverage',
        )
      }

      if (permissions.quote.partner.searchFare) {
        menu['/quote/partner/search-fare'] = getMenu(
          '/quote/partner/search-fare',
          '/quote/partner',
          'Pesquisa de Tarifa',
          'request_quote',
          '/quote/partner/search-fare',
        )
      }
    }

    if (permissions.system.profile) {
      menu['/system/profile'] = getMenu('/system/profile', '/register', 'Perfil', 'recent_actors', null)
      menu['/system/profile/new'] = getMenu('/system/profile/new', '/system/profile', 'Novo', 'group_add', '/system/profile/new')
      menu['/system/profile/search'] = getMenu('/system/profile/search', '/system/profile', 'Pesquisa', 'search', '/system/profile/search')
    }

    if (permissions.system.carrier) {
      menu['/system/carrier'] = getMenu('/system/carrier', '/register', 'Portador', 'badge', null)
      menu['/system/carrier/new'] = getMenu('/system/carrier/new', '/system/carrier', 'Novo', 'group_add', '/system/carrier/new')
      menu['/system/carrier/search'] = getMenu('/system/carrier/search', '/system/carrier', 'Pesquisa', 'search', '/system/carrier/search')
    }

    if (permissions.pickup.pickupRoute) {
      menu['/pickup/pickup-route'] = getMenu('/pickup/pickup-route', '/register', 'Rota de Coleta', 'map', null)
      menu['/pickup/pickup-route/new'] = getMenu('/pickup/pickup-route/new', '/pickup/pickup-route', 'Nova', 'add', '/pickup/pickup-route/new')
      menu['/pickup/pickup-route/search'] = getMenu(
        '/pickup/pickup-route/search',
        '/pickup/pickup-route',
        'Pesquisa',
        'search',
        '/pickup/pickup-route/search',
      )
    }

    if (permissions.system.user) {
      menu['/system/user'] = getMenu('/system/user', '/register', 'Usuário', 'person', null)
      menu['/system/user/new'] = getMenu('/system/user/new', '/system/user', 'Novo', 'add', '/system/user/new')
      menu['/system/user/search'] = getMenu('/system/user/search', '/system/user', 'Pesquisa', 'search', '/system/user/search')
    }
  }
}
