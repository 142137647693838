export interface PrintTagFmOrderList {
  value: PrintTagFmOrderEnum | null
  label: string
}

export enum PrintTagFmOrderEnum {
  CreationDate = 'CreationDate',
  RecipientName = 'RecipientName',
  OrderNumber = 'OrderNumber',
  FiscalNoteNumber = 'FiscalNoteNumber',
}

export const PrintTagFmOrderOptions: Array<PrintTagFmOrderList> = [
  { value: PrintTagFmOrderEnum.CreationDate, label: 'Data de Criação' },
  { value: PrintTagFmOrderEnum.RecipientName, label: 'Nome do Destinatário' },
  { value: PrintTagFmOrderEnum.OrderNumber, label: 'Núm. Pedido' },
  { value: PrintTagFmOrderEnum.FiscalNoteNumber, label: 'Núm. Nota Fiscal' },
]
