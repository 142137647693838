export interface FieldProps {
  name: string
  desktopSize?: number
  tabletSize?: number
  disabled?: boolean
  [x: string]: any
}

export enum TypeMask {
  Cep,
  Document,
}

export const formatDocument = (value: string): string => {
  if (value.length > 11) {
    return value.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})+?$/, '$1.$2.$3/$4-$5')
  } else if (value.length <= 11) {
    return value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})+?$/, '$1.$2.$3-$4')
  }

  return ''
}
