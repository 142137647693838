import Yup from 'utils/yup'

export const defaultValues = {
  initialCreationDate: null,
  finalCreationDate: null,
  field: null,
  recipientName: null,
  recipientDocument: null,
  orderNumber: null,
  fiscalNoteNumber: null,
  trackingCode: null,
}

export const validationSchema = Yup.object().shape({
  initialCreationDate: Yup.date().nullable(),
  finalCreationDate: Yup.date().nullable(),
  field: Yup.string().nullable(),
  recipientName: Yup.string().nullable().max(100, 'O destinatário deve ter no máximo 100 caracteres'),
  recipientDocument: Yup.string().nullable().max(19, 'O cpf/cnpj deve ter no máximo 19 caracteres'),
  orderNumber: Yup.string().nullable().max(50, 'O núm. pedido deve ter no máximo 50 caracteres'),
  fiscalNoteNumber: Yup.string().nullable().max(30, 'O núm. nf deve ter no máximo 30 caracteres'),
  trackingCode: Yup.string().nullable().max(30, 'O tracking deve ter no máximo 30 caracteres'),
})
