import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { NumberLabel, TextLabel } from 'components/label'
import { Cell } from 'components/core'
import { formatCepMask } from 'components/address/types'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Endereço
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='zipCode' label='Cep' value={formatCepMask(order.recipient.address.zipCode)} desktopSize={2} tabletSize={4} />
          <TextLabel name='state' label='Estado' value={order.recipient.address.state} desktopSize={2} tabletSize={4} />
          <NumberLabel
            name='cityIbgeCode'
            label='Cód. Ibge da Cidade'
            value={order.recipient.address.cityIbgeCode}
            maxLength={7}
            desktopSize={2}
            tabletSize={4}
          />
          <TextLabel name='city' label='Cidade' value={order.recipient.address.city} desktopSize={3} tabletSize={4} />
          <TextLabel name='neighborhood' label='Bairro' value={order.recipient.address.neighborhood} desktopSize={3} tabletSize={4} />
          <TextLabel name='street' label='Logradouro' value={order.recipient.address.street} desktopSize={6} tabletSize={4} />
          <TextLabel name='number' label='Número' value={order.recipient.address.number} desktopSize={2} tabletSize={2} />
          <TextLabel name='complement' label='Complemento' value={order.recipient.address.complement} desktopSize={4} tabletSize={2} />
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
